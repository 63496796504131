import { GetStaticPropsContext } from "next";
import NextPage, {
  getStaticProps as getStaticPropsLandingPage,
  NextPageProps,
} from "./[...slug]";
import { PageBaseProps } from "../types";

export default function Home(props: NextPageProps): JSX.Element {
  return NextPage(props);
}

export async function getStaticProps(
  context: GetStaticPropsContext
): Promise<PageBaseProps> {
  context.params = {};
  context.params.slug = "home";
  return getStaticPropsLandingPage(context);
}
